@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Nexa Light';
  src: url("../fonts/text/nexa/NexaLight.woff") format("woff"), url("../fonts/text/nexa/NexaLight.ttf") format("truetype");
}

@font-face {
  font-family: 'Nexa Bold';
  font-weight: bold;
  src: url("../fonts/text/nexa/NexaBold.woff") format("woff"), url("../fonts/text/nexa/NexaBold.ttf") format("truetype");
}